



























import {Component, Vue} from "vue-property-decorator";
import TextInput from "@/components/form/TextInput.vue";
import FormButton from "@/components/form/Button.vue";
import axios from "axios";
import {captureException} from "@sentry/browser";

@Component({
  components: {
    TextInput,
    FormButton,
  },
})
export default class PasswordResetRequestForm extends Vue {
  public email = "";
  public emailWarning = false;

  public success = false;

  public request() {
    if (this.validate()) {
      axios.post("/auth/password-reset/request", {email: this.email})
        .then(() => {
          this.success = true;
        })
        .catch((err) => {
          captureException(err);
          alert("something went wrong");
        });
    }
  }

  private validate() {
    this.emailWarning = !emailRegex.test(this.email);

    return !this.emailWarning;
  }

}

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
